import { noop } from "@/utils";
import { ease } from "@/utils/ease";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { AnimatePresence, Reorder, animate } from "motion/react";
import React, { useLayoutEffect, useRef } from "react";
import { colors, scrollbarWidth } from "../../styles/global.styles";

export const listItemStyle = css({
  listStyleType: "none",
  display: "flex",
  gap: "1em",
  padding: "1em 0",
  flexDirection: "row",
  width: "100%",
  height: "5em",
  alignItems: "center",
  color: colors.primaryTextColor,
  cursor: "pointer",
  fontWeight: "600",
  overflow: "hidden",
  marginBottom: "0.5em",

  // emotion says this selector could be problematic, but it seems fine since SSR isn't being used
  [`:first-child${
    import.meta.env.DEV
      ? // this string is removed in production
        " /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */"
      : ""
  }`]: {
    marginTop: "0",
  },
});

export const ListItem = styled.li(listItemStyle);

export const editListItemStyle = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "5em",
  borderRadius: "20px",
  margin: "1em 0",
  paddingLeft: "1em",
  paddingRight: "1em",

  transition: "background-color 0.1s ease, opacity 0.35s ease",
  ":hover": {
    backgroundColor: colors.secondaryBackground,
  },
});

export const editListItemStyleDisabled = css({
  transition: "opacity 0.35s ease",
  ":hover": {
    backgroundColor: "inherit",
  },
  opacity: "0.25",
});

export const spacerSize = "12px";

const listContainerStyle = css({
  listStyleType: "none",
  paddingInline: "0",
  marginBlock: "0",
  marginTop: `calc(1em - ${spacerSize} / 2)`,
  // ! negative margin
  marginBottom: `-${spacerSize}`,
  width: `calc(100% - 3em + (${scrollbarWidth} + 1.5px) * 2)`,
  transform: `translateX(calc(${scrollbarWidth} + 1.5px))`,
  paddingRight: `calc(1.5em / 2 - ${scrollbarWidth} / 2)`,
  height: "100%",
  borderRadius: "6px",
  // will always show the scrollbar
  overflowY: "scroll",
  overflowX: "hidden",
  "::-webkit-scrollbar-track": {
    margin: `${spacerSize} 0`,
  },
  ":focus-visible": {
    outline: "none",
  },
});

type ListContainerProps = (
  | {
      animateOrder?: never;
      items?: never;
      setItems?: never;
    }
  | {
      animateOrder: true;
      items: any[];
      /**
       * if drag is disabled, setItems is not required and will default to noop
       */
      setItems?: React.Dispatch<React.SetStateAction<any[]>>;
    }
) &
  React.ComponentProps<"ul"> & {
    onScrollEnd?: () => void;
    children: React.ReactNode;
    onScrollBarShown?: () => void;
    onScrollBarHidden?: () => void;
  };

export function ListContainer({
  onScrollEnd,
  children,
  animateOrder,
  items,
  setItems = noop,
  onScrollBarShown,
  onScrollBarHidden,
  ...props
}: ListContainerProps) {
  const listRef = useRef<HTMLOListElement>(null!);

  useLayoutEffect(() => {
    const _resizeListOnOverFlow = (instant = false) => {
      const showScrollbar =
        listRef.current.scrollHeight > listRef.current.clientHeight;

      if (showScrollbar) {
        onScrollBarShown?.();
      } else {
        onScrollBarHidden?.();
      }

      animate(
        listRef.current,
        {
          "--scrollbar-alpha": showScrollbar ? 1 : 0,
        },
        {
          duration: instant ? 0 : 0.5,
          ease: ease,
        }
      );
    };

    _resizeListOnOverFlow(true);

    const resizeListOnOverFlow = () => _resizeListOnOverFlow();

    const resizeObserver = new ResizeObserver(resizeListOnOverFlow);

    resizeObserver.observe(listRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [onScrollBarShown, onScrollBarHidden]);

  // Add the scroll event listener
  useLayoutEffect(() => {
    if (!onScrollEnd) return;

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = listRef.current;
      const scrollRatio = scrollTop / (scrollHeight - clientHeight);

      if (scrollRatio > 0.95) {
        onScrollEnd();
      }
    };

    listRef.current.addEventListener("scroll", handleScroll);
    return () => {
      listRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [onScrollEnd]);

  if (animateOrder) {
    return (
      <Reorder.Group
        as="ol"
        axis="y"
        values={items}
        onReorder={setItems}
        layoutScroll
        ref={listRef}
        {...(props as any)}
        css={listContainerStyle}
      >
        <Spacer />
        <AnimatePresence>{children}</AnimatePresence>
        <Spacer />
        <TopShadow />
        <BottomShadow />
      </Reorder.Group>
    );
  }

  return (
    <ol
      // ? avoiding typescript bug with HTMLUListElement
      // oxlint-disable-next-line no-explicit-any
      ref={listRef as any}
      {...props}
      css={listContainerStyle}
    >
      <Spacer />
      {children}
      <Spacer />
      <TopShadow />
      <BottomShadow />
    </ol>
  );
}

const Spacer = styled.div({
  height: spacerSize,
});

const ShadowCss = css({
  position: "sticky",
  width: "100%",
  boxShadow: `0 0 8px 8px ${colors.primaryBackground}`,
});

const TopShadow = styled.div([
  ShadowCss,
  {
    bottom: "100%",
    top: "0",
  },
]);

const BottomShadow = styled.div([
  ShadowCss,
  {
    top: "100%",
    bottom: "0",
  },
]);
