import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

const breakpoints = [420, 768, 992, 1200, 1444];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export const Root = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  alignItems: "center",
});

export const InfoContainer = styled.div({
  height: "40%",
  display: "flex",
  width: "100%",
  padding: "0 20%",
  flexDirection: "column",
  overflowY: "scroll",
  mask: "linear-gradient(to bottom, transparent, black 2%, black 98%, transparent)",
  [mq[0]]: {},
});

export const InfoSection = styled.div({
  borderBottom: "1px solid",
  borderColor: colors.secondaryBackground,
  paddingBottom: "2em",
  paddingTop: "2em",
});

export const name = css({
  color: colors.primaryTextColor,
  fontSize: "1.5rem",
  textAlign: "center",
  [mq[4]]: {
    fontSize: "2.188rem",
    marginTop: "5%",
  },
});

export const callOptions = css({
  height: "10%",
  backgroundColor: colors.secondaryBackground,
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  paddingLeft: "8px",
  paddingRight: "8px",
  gap: "1vw",
  [mq[0]]: {
    justifyContent: "center",
  },
  [mq[2]]: {
    gap: "1.5vw",
  },
  [mq[3]]: {
    gap: "2vw",
  },
});

export const callOptionsIcon1 = css({
  [mq[4]]: {
    height: "1.5em",
    width: "1.5em",
  },
});

export const callOptionsIcon2 = css({
  [mq[3]]: {
    height: "1.25em",
    width: "1.25em",
  },
  [mq[4]]: {
    height: "1.75em",
    width: "1.75em",
  },
});

export const avatar = css({
  height: "4rem",
  width: "4rem",
  fontSize: "2rem",
  borderRadius: "50%",
  [mq[3]]: {
    height: "6rem",
    width: "6rem",
    fontSize: "3rem",
  },
  [mq[4]]: {
    height: "9.375rem",
    width: "9.375rem",
    fontSize: "4rem",
  },
});

export const title = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "2vh",
});

export const logo = css({
  marginLeft: "0.3em",
  fontSize: "inherit",
  alignItems: "center",
  color: colors.primaryAccentColor,
  ":disabled": {
    color: colors.secondaryTextColor,
    opacity: 0.5,
  },
});

export const infoTitle = css({
  fontSize: "0.8em",
  color: colors.secondaryTextColor,
});

export const infoPhone = css({
  display: "flex",
  flexDirection: "column",
});

export const infoDetails = css({
  color: colors.primaryTextColor,
  paddingRight: "5%",
  display: "flex",
  alignItems: "center",
  gap: "1vw",
  fontSize: "1em",
});

export const companyCss = css({
  color: colors.secondaryTextColor,
});

export const type = css({
  color: colors.primaryAccentColor,
  fontSize: "0.75rem",
  marginTop: "1em",
  marginBottom: "0.5em",
});
